<template>
  <div id="schedasuora" class="centre" v-if="this.annuarioDBOpen">
    <div class="surtitre" style="padding-top: 20px">
      <router-link to="/annuario/suore/"> Ancelle dell'Immacolata </router-link>
    </div>
    <Card style="margin: 10px">
      <template #title>
        <div class="p-card-name">
          Suor {{ this.suora.cognome.toUpperCase() }}
          {{ this.suora.nome }}
        </div>
      </template>
      <template #content>
        <div class="memberCardImg">
          <!--      <img :src="srcImg(membro.immagine)" /> -->
        </div>

        <div class="background-2">
          <!-- Data nascita -->
          <div class="flex">
            <div class="memberLabels">
              <strong>Compleanno:</strong>
            </div>
            <div class="memberData">
              {{ datanascita(this.suora.nascita) }}
            </div>
          </div>
          <div class="flex">
            <div class="memberLabels">
              <strong>Onomastico:</strong>
            </div>
            <div class="memberData">
              {{ datanascita(this.suora.onomastico) }}
            </div>
          </div>
        </div>
        <div class="background-1">
          <div class="flex">
            <div class="memberLabels">
              <strong>E-mail:</strong>
            </div>
            <div class="memberMail">
              <a :href="mailaddr(this.suora.email)">{{ this.suora.email }}</a>
            </div>
          </div>
          <div class="flex">
            <div class="memberLabels">
              <strong>Tel. Cellulare:</strong>
            </div>
            <div class="memberData">{{ this.suora.cellulare }}</div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
import { mapState } from 'vuex';
import $ from 'jquery';

export default {
  name: "SchedaSuora",
  components: { Card },
  props: {
    noSuora: String,
    id: String,
  },
  data() {
    return {
      suora: [],
      mesi: [
        "gennaio",
        "febbraio",
        "marzo",
        "aprile",
        "maggio",
        "giugno",
        "luglio",
        "agosto",
        "settembre",
        "ottobre",
        "novembre",
        "dicembre",
      ],
    };
  },
  computed: {
    ...mapState(['annuarioDB', 'annuarioDBOpen'])
  },
  created() {
    if ( !this.annuarioDBOpen ) {
      console.log('suorescheda.vue created');
      this.$store.dispatch("getAnnuario"); 
    }
    else {
      var cpt = 0;
      for (let membro of this.annuarioDB[2]) {
        if (membro.immagine == this.$route.params.id) {
          break;
        }
        cpt++;
      }
      this.suora = this.annuarioDB[2][parseInt(cpt)];
      console.log( 'this suora = ', this.suore );
    }
    $( "html, body" ).animate({ scrollTop: 0 }, "slow"); // scroll to top of page
  },
  watch: {
    annuarioDB: function() {
      var cpt = 0;
      console.log('soeurs avant boucle', this.annuarioDB);
      for (let membro of this.annuarioDB[2]) {
        if (membro.immagine == this.$route.params.id) {
          break;
        }
        cpt++;
      }
      this.suora = this.annuarioDB[2][parseInt(cpt)];
      console.log( 'this.suora', this.suora );
    }
  },
  methods: {
    mailaddr(mail) {
      return "mailto:" + mail;
    },
    datanascita(laData) {
      var dN = laData.split(".");
      return parseInt(dN[0]) + " " + this.mesi[parseInt(dN[1]) - 1];
    },
  },
  mounted() {
    this.$store.dispatch( "setHighlight", { on: true } ); // highlight the tab of tabMenu
  },
  unmounted() {
    this.$store.dispatch( "setHighlight", { on: false } ); // highlight the tab of tabMenu
  },
};
</script>

<style>
#schedasuora .p-card-body {
  padding-top: 0px !important;
}
#schedasuora .p-card-content {
  text-align: left !important;
}
#schedasuora .memberLabels {
  min-width: 140px;
}
#schedasuora .p-card-name {
  background-color: #6c8cbf;
  color: #fffa8f;
  padding: 10px;
  border-radius: 10px;
}
#schedasuora .background-1,
#schedasuora .background-2 {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 0px;
}
#schedasuora .background-1 {
  background-color: #fffdd3;
  border: 1px solid rgb(255, 230, 0);
}
#schedasuora .background-2 {
  background-color: white;
  border-left: 1px solid rgb(255, 230, 0);
  border-right: 1px solid rgb(255, 230, 0);
}
#schedasuora .border-bottom {
  border-bottom: 1px solid rgb(255, 230, 0);
}
#schedasuora .memberMail a {
  text-decoration: underline;
}
#schedasuora .p-card .p-card-content {
  padding: 0 !important;
}
</style>