<template>
  <div>
    <div class="navigation-bar">
      <div class="back-title flex">
        <div><img class="back-img" src="@/assets/img/back.png"></div>
        <div><strong><a href="#" onclick="history.back()">Menu Accoglienza</a></strong></div>
      </div>
      <div>
        <div class="inline-block link" @click="printContent"><strong>Stampa</strong></div>
        &nbsp; 
        <div class="mt5 inline-block link" @click="dateOnOff"><strong>Data</strong></div>
      </div>
    </div>

    <Dialog v-model:visible="visiblePiano1" :dismissableMask="true" modal header="1° piano" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <p><img style="width: 100%" src="@/assets/img/piano1.png"></p>
    </Dialog>
    <Dialog v-model:visible="visiblePiano3" :dismissableMask="true" modal header="3° piano" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <p><img style="width: 100%" src="@/assets/img/piano3.png"></p>
    </Dialog>
    <Dialog v-model:visible="visiblePiano4" :dismissableMask="true" modal header="4° piano" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <p><img style="width: 100%" src="@/assets/img/piano4.png"></p>
    </Dialog>

    <div id="calendarForm" class="mb10">
      <Calendar v-if="dateOn" 
        v-model="laDate" 
        showIcon 
        dateFormat="dd/mm/yy"
        @date-select="searchForEvents" />
    </div>

    <div id="printable-content">
      <div class="submenuTitle colorred" style="margin-bottom: 0px;">Partenze / Arrivi</div>
      <div class="submenuTitle-2 mb20">Permanenza in Collegio dal <strong>{{ this.laDate }}</strong></div>

      <div class="result-listing">

        <div class="header flex">
          <div class="header-data">Data</div>
          <div class="header-AP">A/P</div>
          <div class="header-nome">Nome</div>
          <div class="header-volo">Volo / Treno / Bus</div>
          <div class="header-stanza">Stanza</div>
        </div>

        <div class="htmlContent-sep"></div>
        <div v-if="loading"><img src="@/assets/img/wheel.gif"></div>

        <div>
          <div v-if="this.displayList && !this.loading" class="htmlContent-body">
            <div>
              <div v-for="(contenuto, index) in this.contentDef" :key="index" >

                <div>
                  <div class="flex" :style="{ 'margin-top': contenuto.marginTop }">
                    <div class="flex bold" :class="contenuto.colore">
                      <div class="line-data" v-if="contenuto.marginTop == '8px'">{{ contenuto.dayDay }}</div>
                      <div class="line-data" v-else>&nbsp;</div>
                      <div class="line-day" v-if="contenuto.marginTop == '8px'">{{ contenuto.dayData.substr(8, 2) }}.{{ contenuto.dayData.substr(5, 2) }}</div>
                      <div class="line-day" v-else>&nbsp;</div>
                      <div class="line-AP">{{ contenuto.arrPart }}</div>
                      <div class="line-nome"><a :href="contenuto.lien" target="new">{{ contenuto.name }}</a></div>
                    </div>  
                    <div class="line-volo">{{ contenuto.viaggio }}</div>
                    <div style="cursor: pointer;" :class='contenuto.stanzaClass' @click="displayImg(contenuto.stanza)">{{ contenuto.stanza }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import Calendar from 'primevue/calendar';
import axios from 'axios';
import $ from 'jquery';

export default {
  name: "accoglienza-partenza-arrivi",
  components: { Calendar, Dialog },
  data() {
    return {
      laDate: "",
      dateOn: false,
      calenders: [],
      calendersJSON: "",
      evenements: {},
      menuDisplay: 0,
      displayList: false,
      loading: true,
      content: [],
      contentDef: [],
      vars: {},
      descriptionLines: [],
      sostitutoDi: "",
      dayBreak: "",
      styleMT: "",
      laDateDebutListing: null,
      visiblePiano1: false,
      visiblePiano3: false,
      visiblePiano4: false,
      pianta: "Pianta"
    };
  },
  mounted() {
    // Date of today
    const today = new Date();
    this.searchForEvents( today );
  },
  methods: {

    displayImg(stanza) {
      console.log( 'stanza = ', stanza );
      if ( stanza == 1 ) { this.visiblePiano4 = true; }
      if ( stanza > 1 && stanza < 5 ) { this.visiblePiano3 = true; }
      if ( stanza > 4 ) { this.visiblePiano1 = true; }
    },

    printContent() {
      $('.navigation-bar').css('display', 'none')
      $('.p-tabmenu').css('display', 'none')
      $('#calendarForm').css('display', 'none')
      window.print();
      $('.navigation-bar').css('display', 'flex')
      $('.p-tabmenu').css('display', 'block')
      $('#calendarForm').css('display', 'block')
    },

    searchForEvents( dateParam ) {
      console.log('dateParam = ', dateParam)

      this.loading = true;

      const yyyy = dateParam.getFullYear();
      let mm = dateParam.getMonth() + 1; // Months start at 0!
      let dd = dateParam.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      this.laDate = dd + '/' + mm + '/' + yyyy;
      this.laDateDebutListing = dateParam

      this.fetchCalendar(6);
    },

    dateOnOff() {
      this.dateOn = !this.dateOn;
    },

    async fetchCalendar(months) {

      console.log('ladate =', "---" + this.laDate)

      this.evenements = {};

      var ladate = this.laDate.split("/");
      var newLaDate = ladate[2] + '-' + ladate[1] + '-' + ladate[0];

      var laDate_moins1_obj = new Date( newLaDate );
      laDate_moins1_obj.setDate(laDate_moins1_obj.getDate() - 1);
      var dateDebutVariable = laDate_moins1_obj.toISOString().substring(0, 10);

      this.calenders[0] = "2kosiohqsfvs0utf7n4k7ebfhs@group.calendar.google.com"; // Sostituti's Calendar
      this.calenders[1] = "penitenzieri.vaticano@gmail.com"; // Penitenzieri's Calendar
      this.calenders[2] = "2e12cgtdeigb9uoqnjgen64qh4@group.calendar.google.com"; // Suore Ancelle dell'Immacolata
      this.calendersJSON = JSON.stringify(this.calenders);

      axios.get(this.$store.state.host + "php/getCalendarOspitiFrati.php", { params: { periode: months, cal: this.calendersJSON, debut: dateDebutVariable } })
        .then(response => {

          console.log('response = ', response.data );
          
          this.evenements = response.data[1];
          console.log('evenement = ', this.evenements);

          this.content = [];
          this.contentDef = [];
          for (var i = 0; i < this.evenements.length; i++) {
            this.vars = {};

            // *********
            // DATE 1 **
            // *********
            this.vars.dayData = this.evenements[i].startDate;
            var ladate = new Date( this.vars.dayData );
            var dayDay = ladate.toLocaleDateString( 'it-IT', { weekday: 'short' } );
            dayDay = dayDay.charAt(0).toUpperCase() + dayDay.slice(1)

            this.vars.dayDay  = dayDay;
            this.vars.name    = this.evenements[i].name;
            this.vars.stanza  = this.evenements[i].stanza;
            this.vars.stanzaClass = this.vars.stanza != "" ? "stanzaNumber2" : "";
            this.vars.lien    = this.evenements[i].lien;

            // détermine si Assenza/Vacanza di un Penitenziere ou pas
              const regex = /assenz[ae]|vacanz[ae]/gi;
            const assVacPen = this.evenements[i].type.search(regex);

            // traitement de la description
            this.descriptionLines = [];
            if ( this.evenements[i].description != null ) {
              const descLines = this.evenements[i].description.split(String.fromCharCode(10)); // extract every lines the desc infos
              for (var x = 0; x < descLines.length; x++) { // parse every line of the desc
                var lineElement = descLines[x].split('='); // split the line to retrieve the elements that are separated with =
                this.descriptionLines[lineElement[0].toLowerCase()] = lineElement[1];
              }
            }

            var partenza = this.descriptionLines.partenza ? this.descriptionLines.partenza : "";
            var arrivo   = this.descriptionLines.arrivo ? this.descriptionLines.arrivo : ""
            
            this.vars.viaggio = assVacPen >= 0 ? partenza : arrivo;
            this.vars.arrPart = assVacPen >= 0 ? "P" : "A";
            this.vars.colore = this.vars.arrPart == "P" ? "bleu" : "bordeaux";
            this.vars.tel     = this.descriptionLines.tel ? this.descriptionLines.tel : "";

            console.log('vars 1 = ', this.vars )
            this.content.push( this.vars );

            // *********
            // DATE 2 **
            // *********
            this.vars = {};

            this.vars.dayData = this.evenements[i].endDate;
            ladate = new Date( this.vars.dayData );
            dayDay = ladate.toLocaleDateString( 'it-IT', { weekday: 'short' } );
            dayDay = dayDay.charAt(0).toUpperCase() + dayDay.slice(1)
            
            this.vars.dayDay  = dayDay;
            this.vars.viaggio = assVacPen >= 0 ? arrivo : partenza;
            this.vars.arrPart = assVacPen >= 0 ? "A" : "P";
            this.vars.colore  = this.vars.arrPart == "P" ? "bleu" : "bordeaux";
            this.vars.name    = this.evenements[i].name;
            this.vars.lien    = this.evenements[i].lien;
            this.vars.stanza  = "";
            this.vars.stanzaClass = this.vars.stanza != "" ? "stanzaNumber2" : "";
            this.vars.tel     = "";

            this.content.push( this.vars );
            console.log('vars 2 = ', this.vars )
          } 

          // *******************************
          // Sort the evenements by DATE ***
          // *******************************
          this.content.sort(function (a, b) {
            return new Date(a.dayData) - new Date(b.dayData);
          });

          console.log('content après le sort = ', this.content)
          // insère le Margin-top de 8px si rupture de date arrivée-départ
          // supprime également les dates de début qui sont inférieures à this.laDate
          var dBreak = "";
          for (var key in this.content) {
              console.log('element = ',  this.content[key] );
              console.log('daydata = ', this.content[key].name, ' list = ', this.content[key].dayData)

              var dateCompare = new Date(this.content[key].dayData);
              this.laDateDebutListing.setHours(0, 0, 0, 0);
              console.log('laDateDebutListing = ', this.laDateDebutListing)
              console.log('dateCompare = ', dateCompare)
              if ( dateCompare >= this.laDateDebutListing ) {
                if ( this.content[key].dayData != dBreak ) {
                  this.content[key].marginTop = '8px'
                  dBreak = this.content[key].dayData  }
                else {
                  this.content[key].marginTop = '0px'
                }
                // Inclus le record dans la liste définitive
                this.contentDef.push( this.content[key] )
              }
          }

          console.log(' content vars = ', this.contentDef)

          this.loading = false;
          this.displayList = true;

        })
    },
  }
}
</script>