<template>
  <div id="altroCategoria" class="centre" v-if="this.altroDB[0]">
    <div class="surtitre" style="padding-top: 20px">
      <router-link to="/altro/"> Altro </router-link>
      <div class="titre-principal" style="padding-bottom: 20px">
        {{ this.altroDB[0][0].categoria_label }}
      </div>
    </div>

    <div
      class="list-center"
      v-for="categoria in this.altroDB[1]"
      :key="categoria.id"
    >
      <a
        href="#"
        @click="openDialog(categoria.id)"
        v-if="verifyCategory(categoria.label)"
      >
        <Card style="margin: 3px 10px">
          <template #title>
            <div class="p-card-name left">
              <div class="p-card-label">{{ categoria.label }}</div>
              <div class="p-card-desc">{{ categoria.desc }}</div>
            </div>
          </template>
        </Card>
      </a>
      <Dialog
        :header="categoria.label"
        v-model:visible="displayDialog[categoria.id]"
        :style="{ width: '90vw', 'max-width': '680px' }"
      >
        <div v-if="categoria.contenuto_links">
          <!-- display if a links content -->
          <div
            v-for="(links, index) in categoria.contenuto_links"
            :key="index"
            class="subcategoria"
          >
            <Links :linkTitle="links.fichier" />
          </div>
        </div>
        <div v-else>
          <!-- display if a links content -->
          <div v-html="categoria.contenuto"></div>
        </div>
        <div>&nbsp;</div>
      </Dialog>
    </div>
  </div>
</template>

<script>
import Card from "primevue/card";
import Links from "/src/components/Links.vue";
import Dialog from "primevue/dialog";
import { mapState } from "vuex";
import $ from "jquery";

export default {
  name: "AltroCategoria",
  components: { Card, Dialog, Links },
  data() {
    return {
      afficheScheda: false,
      displayDialog: [],
      basilicaEvents: [],
      currentDialogOpen: null,
    };
  },
  computed: {
    ...mapState(["altroDB", "altroDBOpen"]),
  },
  created() {
    console.log("the id = ", this.$route.params.id);
    if (!this.$altroDB) {
      this.$store.dispatch("getAltroCategoria");
    }
    $("html, body").animate({ scrollTop: 0 }, "slow"); // scroll to top of page
  },
  methods: {
    openDialog(index) {
      this.closeDialog(this.currentDialogOpen);
      this.displayDialog[index] = true;
      this.currentDialogOpen = index;
    },
    closeDialog(index) {
      this.displayDialog[index] = false;
    },
    verifyCategory(cat) {
      var adminOK = localStorage.getItem("passAnnuario");
      if (cat == "Capitolo conventuale" && (!adminOK || adminOK == 0))
        return false;
      else return true;
    },
    viewPDF(fichier) {
      window.location.href = fichier;
    },
  },
  mounted() {
    this.$store.dispatch("setHighlight", { on: true }); // highlight the tab of tabMenu
    let embedSomeScript = document.createElement("script");
    embedSomeScript.setAttribute(
      "src",
      this.$store.state.host + "js/someJS.js"
    );
    document.head.appendChild(embedSomeScript);
    let embedPdfScript = document.createElement("script");
    embedPdfScript.setAttribute(
      "src",
      "https://documentcloud.adobe.com/view-sdk/main.js"
    );
    document.head.appendChild(embedPdfScript);
  },
  unmounted() {
    this.$store.dispatch("setHighlight", { on: false });
  }, // highlight the tab of tabMenu
};
</script>

<style>
#altroCategoria .p-card-body {
  padding: 0 !important;
}
#altroCategoria .p-card-title {
  margin-bottom: 0 !important;
}
#altroCategoria .p-component {
  background-color: #219862;
  border-radius: 5px;
}
#altroCategoria .p-card-content {
  display: none !important;
}
#altroCategoria .memberLabels {
  min-width: 140px;
}
#altroCategoria .p-card-name {
  background-color: #219862;
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
}
.p-card-label {
  font-size: 22px !important;
}
.p-card-desc {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1em;
}
.p-dialog .p-dialog-header {
  padding-bottom: 10px !important;
}
</style>