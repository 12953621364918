<template>
  <div id="accoglienza">
    <keep-alive>
      <img src="@/assets/img/logo_penitenzieri.png" width="100" />
    </keep-alive>
    <div class="menu submenu">
      <div class="submenuTitle">
        ACCOGLIENZA
      </div>
      <div>
        <router-link :to="{ name: 'AccoglienzaSostitutiOspiti', params : { date: '2023-01-01' }}">
          Sostituti / Ospiti 
        </router-link>
      </div>
      <div>
        <router-link :to="{ name: 'AccoglienzaVacanze' }">
          Assenza Penitenzieri
        </router-link>
      </div>
      <div>
        <router-link :to="{ name: 'AccoglienzaPartenzeArrivi' }">
          Partenze / Arrivi
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: "accoglienza-home",
  created() {
    $( "html, body" ).animate({ scrollTop: 0 }, "slow"); // scroll to top of page
  },
  methods: {
  },
  computed:{ 
  }
};
</script>