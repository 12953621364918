<template>
  <div id="vaticano" v-if="this.annuarioDBOpen">
    <div class="surtitre" style="padding-top: 20px">
      <router-link to="/annuario/">Annuario</router-link>
    </div>
    <div class="titre-principal centre" style="padding-bottom: 20px">
      Città del Vaticano
    </div>

    <div style="margin: 10px;" v-html="this.annuarioDB[4][0].contenuto"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import $ from 'jquery';

export default {
  name: "vaticano-city",
  computed: {
    ...mapState(['annuarioDB', 'annuarioDBOpen'])
  },
  created() {
    if ( !this.annuarioDBOpen ) {
      console.log('Vaticano.vue created');
      this.$store.dispatch("getAnnuario"); 
    }
    $( "html, body" ).animate({ scrollTop: 0 }, "slow"); // scroll to top of page
  },
  methods: {
    mailaddr(mail) {
      return "mailto:" + mail;
    },
  },
  mounted() {
    this.$store.dispatch( "setHighlight", { on: true } ); // highlight the tab of tabMenu
  },
  unmounted() {
    this.$store.dispatch( "setHighlight", { on: false } ); // highlight the tab of tabMenu
  },
};
</script>

<style>
#vaticano .list-left {
  text-align: left;
  line-height: 1.4em;
}
#vaticano .flex-left-right {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  font-size: 18px;
  line-height: 1.4em;
}
</style>
