<template>
  <div id="suore" v-if="this.annuarioDBOpen">
    <div class="surtitre" style="padding-top: 20px">
      <router-link to="/annuario/">Annuario</router-link>
    </div>
    <div class="titre-principal centre" style="padding-bottom: 20px">
      Ancelle dell'Immacolata
    </div>
    <div class="sitoweb">
      <div><strong>Sito internet:</strong></div>
      <a :href="this.annuarioDB[0][1].sitoweb" target="new">{{
        this.annuarioDB[0][1].sitoweb
      }}</a>
    </div>
    <div
      class="list-center"
      v-for="(membro, index) in this.annuarioDB[2]"
      :key="membro.id"
    >
      <router-link
        :to="{
          name: 'SchedaSuora',
          params: { noSuora: index, id: membro.immagine },
        }"
      >
        <Card style="margin: 10px">
          <template #content>
            Sr <strong>{{ membro.cognome.toUpperCase() }}</strong>
            {{ membro.nome }}
          </template>
        </Card>
      </router-link>
    </div>
  </div>
</template>

<script>
import Card from "primevue/card";
import { mapState } from 'vuex';
import $ from 'jquery';

export default {
  name: "suore-display",
  components: { Card },
  data() {
    return {
      afficheSuore: false,
      suoreDisplay: {},
      schedaSuora: null,
    };
  },
  computed: {
    ...mapState(['annuarioDB', 'annuarioDBOpen'])
  },
  created() {
    if ( !this.annuarioDBOpen ) {
      console.log('suore.vue created');
      this.$store.dispatch("getAnnuario"); 
    }
    $( "html, body" ).animate({ scrollTop: 0 }, "slow"); // scroll to top of page
  },
  mounted() {
    this.$store.dispatch( "setHighlight", { on: true } ); // highlight the tab of tabMenu
  },
  unmounted() {
    this.$store.dispatch( "setHighlight", { on: false } ); // highlight the tab of tabMenu
  },
};
</script>

<style>
#suore .p-card,
#suore .p-card-body {
  border-radius: 10px !important;
}
#suore .p-card-body {
  background-color: #6c8cbf; /* #c9c9ff; */
}
#suore .p-card-content {
  color: #fffa8f;
  font-size: 22px;
}
#suore .list-center a {
  color: #fffa8f;
}
#suore .p-card-content {
  text-align: left !important;
  padding: 0px !important;
}
#suore .memberLabels {
  min-width: 140px;
}
.sitoweb {
  font-size: 20px;
  margin-bottom: 20px;
}
</style>