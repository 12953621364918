<template>
  <div>
    <div class="navigation-bar">
      <div class="back-title flex">
        <div><img class="back-img" src="@/assets/img/back.png"></div>
        <div><strong><a href="#" onclick="history.back()">Menu Accoglienza</a></strong></div>
      </div>
      <div>
        <div class="inline-block link" @click="printContent"><strong>Stampa</strong></div>
        &nbsp; 
        <div class="mt5 inline-block link" @click="dateOnOff"><strong>Data</strong></div>
      </div>
    </div>

    <div id="calendarForm" class="mb10">
      <Calendar v-if="dateOn" 
        v-model="laDate" 
        showIcon 
        dateFormat="dd/mm/yy"
        @date-select="searchForEvents" />
    </div>

    <Dialog v-model:visible="visiblePiano1" :dismissableMask="true" modal header="1° piano" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <p><img style="width: 100%" src="@/assets/img/piano1.png"></p>
    </Dialog>
    <Dialog v-model:visible="visiblePiano3" :dismissableMask="true" modal header="3° piano" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <p><img style="width: 100%" src="@/assets/img/piano3.png"></p>
    </Dialog>
    <Dialog v-model:visible="visiblePiano4" :dismissableMask="true" modal header="4° piano" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <p><img style="width: 100%" src="@/assets/img/piano4.png"></p>
    </Dialog>

    <div id="printable-content">
      <div class="submenuTitle colorred" style="margin-bottom: 0px;">Sostituti / Ospiti</div>
      <div class="submenuTitle-2 mb20">Dal <strong>{{ this.laDate }}</strong></div>

      <div class="byDateChart">
        <div @click="menuClick(1)" id="menu-sostituti-1" :class="[menuDisplay == 1 ? 'menu-selected' : 'menu-disabled']">
          <div>Per Data</div>
        </div>
        <div style="padding: 10px;">&nbsp;&nbsp;</div>
        <div @click="menuClick(2)" id="menu-sostituti-2" :class="[menuDisplay == 2 ? 'menu-selected' : 'menu-disabled']">
          <div>Chart</div>
        </div>
      </div>

      <div class="htmlContent-sep"></div>
      <div v-if="loading"><img src="@/assets/img/wheel.gif"></div>
      <div style="width: 100%">
  
        <div v-if="menuDisplay==1 && this.displayList && !this.loading" class="htmlContent-body">
          <div class="result-box" v-for="(contenuto, index) in this.contentDef" :key="index" >

            <div class="flex">
              <div class="left-side">
                <div class="arrivo-partenza">
                  <span class='rouge'><strong>{{ contenuto.dayStart }}.{{ contenuto.monthStartNum }}</strong></span>
                  - 
                  <span class='rouge'><strong>{{ contenuto.dayEnd }}.{{ contenuto.monthEndNum }}</strong></span>
                </div>
                <div class="stanza">
                  <div>Stanza: <span style="cursor: pointer;" class='stanzaNumber' @click="displayImg(contenuto.stanza)">{{ contenuto.stanza }}</span></div>
                </div>
              </div>
              <div class="right-side">
                <div class="sos"><span :class="contenuto.ospiteSostitutoClass">{{ contenuto.ospiteSostituto }} </span> {{ contenuto.sostitutoDi }}</div>
                <div class="nomSos"><a :href="contenuto.lien" target="new"> {{ contenuto.ospite }}</a></div>
              </div>
            </div>
            <div class="arr-par-volo">
              <div>Arrivo: <strong>{{ contenuto.arrivo }}</strong> </div>
              <div>Partenza: <strong>{{ contenuto.partenza }}</strong> </div>
              <div v-if="contenuto.tel">Tel: <strong>{{ contenuto.tel }}</strong></div>
            </div>
          </div>
        </div>

        <div v-if="menuDisplay==2 && !this.loading" id="timeline" class="htmlContent-body" style="height: 100vh; width: 100%;"></div>
      </div>
    </div>
</div>
</template>

<script>
import Dialog from "primevue/dialog";
import Calendar from 'primevue/calendar'
import axios from 'axios'
import $ from 'jquery';

export default {
  name: "accoglienza-sostituti-ospiti",
  components: { Calendar, Dialog },
  data() {
    return {
      laDate: "",
      dateOn: false,
      calenders: [],
      calendersJSON: "",
      evenements: {},
      menuDisplay: 0,
      displayList: false,
      loading: true,
      content: [],
      contentDef: [],
      vars: {},
      descriptionLines: [],
      sostitutoDi: "",
      laDateDebutListing: null,
      visiblePiano1: false,
      visiblePiano3: false,
      visiblePiano4: false,
    };
  },
  mounted() {
    // Date of today
    const today = new Date();
    this.searchForEvents( today );

  },

  unmounted() {
    window.removeEventListener( 'resize', this.redrawChart );
  },

  methods: {

    displayImg(stanza) {
      console.log( 'stanza = ', stanza );
      if ( stanza == 1 ) { this.visiblePiano4 = true; }
      if ( stanza > 1 && stanza < 5 ) { this.visiblePiano3 = true; }
      if ( stanza > 4 ) { this.visiblePiano1 = true; }
    },

    printContent() {
      $('.navigation-bar').css('display', 'none')
      $('.p-tabmenu').css('display', 'none')
      $('.byDateChart').css('display', 'none')
      $('#calendarForm').css('display', 'none')
      window.print();
      $('.navigation-bar').css('display', 'flex')
      $('.p-tabmenu').css('display', 'block')
      $('.byDateChart').css('display', 'flex')
      $('#calendarForm').css('display', 'block')
    },

    searchForEvents( dateParam ) {

      this.loading = true;

      const yyyy = dateParam.getFullYear();
      let mm = dateParam.getMonth() + 1; // Months start at 0!
      let dd = dateParam.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      this.laDate = dd + '/' + mm + '/' + yyyy;
      this.laDateDebutListing = dateParam

      this.fetchCalendar(6);
      window.addEventListener( 'resize', this.redrawChart );
    },

    dateOnOff() {
      this.dateOn = !this.dateOn;
    },

    redrawChart() {
      if ( this.menuDisplay == 2 ) {
        // eslint-disable-next-line
        google.charts.load('current', { 'packages': ['timeline'] });
        // eslint-disable-next-line
        google.charts.setOnLoadCallback(this.drawChart);
      }
    },

    menuClick(num) {
      window.localStorage.setItem('menuOption', num);
      this.menuDisplay = num;
      if ( num == 2 ) {
        // eslint-disable-next-line
        google.charts.load('current', { 'packages': ['timeline'] });
        // eslint-disable-next-line
        google.charts.setOnLoadCallback(this.drawChart);
      }
    },

    async fetchCalendar(months) {

      console.log('ladate =', "---" + this.laDate)

      this.evenements = {};

      var ladate = this.laDate.split("/");
      var newLaDate = ladate[2] + '-' + ladate[1] + '-' + ladate[0];

      var laDate_moins1_obj = new Date( newLaDate );
      laDate_moins1_obj.setDate(laDate_moins1_obj.getDate() - 1);
      var dateDebutVariable = laDate_moins1_obj.toISOString().substring(0, 10);

      this.calenders[0] = "2kosiohqsfvs0utf7n4k7ebfhs@group.calendar.google.com"; // Sostituti's Calendar
      this.calendersJSON = JSON.stringify(this.calenders);

      axios.get(this.$store.state.host + "php/getCalendarOspitiFrati.php", { params: { periode: months, cal: this.calendersJSON, debut: dateDebutVariable } })
        .then(response => {

          this.evenements = response.data[1];
          console.log('evenement = ', this.evenements);

          this.content = [];
          this.contentDef = [];
          for (var i = 0; i < this.evenements.length; i++) {
            this.vars = {};

            // parse start date
            var dateObj = new Date(this.evenements[i].startDate);
            this.vars.startDate = dateObj;
            this.vars.dayStart = dateObj.toLocaleString("it-IT", { day: "numeric" });
            this.vars.monthStartNum = dateObj.toLocaleString("it-IT", { month: "numeric" });

            // parse end date
            dateObj = new Date(this.evenements[i].endDate);
            this.vars.endDate = dateObj;
            if (this.evenements[i].startHeure == "") {
              dateObj.setDate(dateObj.getDate() - 1);
            }
            this.vars.dayEnd = dateObj.toLocaleString("it-IT", { day: "numeric" });
            this.vars.monthEndNum = dateObj.toLocaleString("it-IT", { month: "numeric" });

            // Add '0' before numbers < 10
            this.vars.dayStart = this.vars.dayStart < 10 ? "0" + this.vars.dayStart : this.vars.dayStart.toString();
            this.vars.dayEnd = this.vars.dayEnd < 10 ? "0" + this.vars.dayEnd : this.vars.dayEnd.toString();
            this.vars.monthStartNum = this.vars.monthStartNum < 10 ? "0" + this.vars.monthStartNum : this.vars.monthStartNum.toString();
            this.vars.monthEndNum = this.vars.monthEndNum < 10 ? "0" + this.vars.monthEndNum : this.vars.monthEndNum.toString();

            // traitement de la description
            this.vars.theDescription = this.evenements[i].description;
            this.vars.type = "";
            this.vars.arrivo = "";
            this.vars.partenza = "";
            this.descriptionLines = [];

            if ( this.vars.theDescription != null ) {
              const descLines = this.vars.theDescription.split(String.fromCharCode(10)); // extract every lines the desc infos
              for (var x = 0; x < descLines.length; x++) { // parse every line of the desc
                var lineElement = descLines[x].split('='); // split the line to retrieve the elements that are separated with =
                this.descriptionLines[lineElement[0].toLowerCase()] = lineElement[1];
              }

              if ( this.descriptionLines.pen ) {
                this.vars.sostitutoDi = "di " + this.descriptionLines.pen;
              }
          
              if ( this.evenements[i].type ) {
                this.vars.type = this.evenements[i].type.trim(); // type = sostituto, ospite, vacanza, assenza
              }
              this.vars.arrivo = this.descriptionLines.arrivo;
              this.vars.partenza = this.descriptionLines.partenza;
            }
            if ( this.descriptionLines.tel ) {
              this.vars.tel = this.descriptionLines.tel;
            }

            this.vars.ospiteSostituto = this.evenements[i].type.trim();
            this.vars.ospite = this.evenements[i].name;
            this.vars.stanza = this.evenements[i].stanza;
            this.vars.lien   = this.evenements[i].lien;
            this.vars.ospiteSostitutoClass = this.vars.ospiteSostituto.toLowerCase() == "ospite" ? "back-orange" : "back-green"

            this.content.push( this.vars ) 
          } 

          // *******************************
          // Sort the evenements by DATE ***
          // *******************************
          this.content.sort(function (a, b) {
            return new Date(a.dayData) - new Date(b.dayData);
          });

          console.log('content après sort', this.content)
          // supprime également les dates de début qui sont inférieures à this.laDate
          for (var key in this.content) {
            this.laDateDebutListing.setHours(0, 0, 0, 0);
            console.log('daydata = ', this.content[key].startDate)
            console.log('datedebutlisting = ', this.laDateDebutListing)
            if ( this.content[key].startDate >= this.laDateDebutListing ) {
              this.contentDef.push( this.content[key] )  // Inclus le record dans la liste définitive
            }
          }

          console.log(' content vars = ', this.contentDef)

          this.loading = false;
          if (!(this.menuDisplay = localStorage.getItem('menuOption'))) {
            this.menuDisplay = 1;
          }
          this.displayList = true;

          // *********
          // CHART ***
          // *********
          // eslint-disable-next-line
          google.charts.load('current', { 'packages': ['timeline'] });
          // eslint-disable-next-line
          google.charts.setOnLoadCallback(this.drawChart);

        })
    },
    drawChart() {

      console.log('events = ', this.evenements);
      var container = document.getElementById('timeline');
      
      // eslint-disable-next-line  
      var chart = new google.visualization.Timeline(container);
      // eslint-disable-next-line
      var dataTable = new google.visualization.DataTable();

      // eslint-disable-next-line
      google.visualization.events.addListener(chart, 'ready', function () {
        console.log('fini de charger')
        $("[text-anchor=end]").attr("fill", "#c10d32");
        $("[text-anchor=end]").attr("stroke", "#c10d32");
        $("[text-anchor=end]").attr("stroke-width", "1");
      });

      dataTable.addColumn({ type: 'string', id: 'Term' });
      dataTable.addColumn({ type: 'string', id: 'Name' });
      dataTable.addColumn({ type: 'date', id: 'Start' });
      dataTable.addColumn({ type: 'date', id: 'End' });

      //var theRows = [];
      var rowsTab = [];
      for (var i = 0; i < this.evenements.length; i++) {

        var dateStartObj = new Date(this.evenements[i].startDate);
        var dateEndObj = new Date(this.evenements[i].endDate);
        if (this.evenements[i].startHeure == "") {
          dateEndObj.setDate(dateEndObj.getDate() - 1);
        }

        var line = [this.evenements[i].stanza, this.evenements[i].name.trim(), dateStartObj, dateEndObj];
        rowsTab.push(line);
        console.log('line = ', line);
      }
      console.log('rowsTab = ', rowsTab);
      dataTable.addRows(rowsTab);

      var options = {
        vAxis : { 
          textStyle : {
              fontSize: 33 // or the number you want
          }

      }

      };
      chart.draw(dataTable, options);
    }
  }
}
</script>