<template>
  <div>
    <div class="navigation-bar">
      <div class="back-title flex">
        <div><img class="back-img" src="@/assets/img/back.png"></div>
        <div><strong><a href="#" onclick="history.back()">Menu Accoglienza</a></strong></div>
      </div>
      <div>
        <div class="inline-block link" @click="printContent"><strong>Stampa</strong></div>
        &nbsp; 
        <div class="mt5 inline-block link" @click="dateOnOff"><strong>Data</strong></div>
      </div>
    </div>

    <div id="calendarForm" class="mb10">
      <Calendar v-if="dateOn" 
        v-model="laDate" 
        showIcon 
        dateFormat="dd/mm/yy"
        @date-select="searchForEvents" />
    </div>

    <div class="submenuTitle colorred" style="margin-bottom: 0px;">Assenza Penitenzieri</div>
    <div class="submenuTitle-2 mb20">Dal <strong>{{ this.laDate }}</strong></div>

    <div class="htmlContent-sep"></div>
    <div v-if="loading"><img src="@/assets/img/wheel.gif"></div>
    <div style="width: 100%">
 
      <div v-if="this.displayList && !this.loading" class="htmlContent-body">
        <div v-for="(contenuto, index) in this.contentDef" :key="index">

          <div class="result-box">

            <div class="flex">
              <div class="left-side">
                <div class="arrivo-partenza">
                  <span class='rouge'><strong>{{ contenuto.dayStart }}.{{ contenuto.monthStartNum }}</strong></span>
                  - 
                  <span class='rouge'><strong>{{ contenuto.dayEnd }}.{{ contenuto.monthEndNum }}</strong></span>
                </div>
              </div>
              <div class="right-side">
                <div class="sos" :class="contenuto.assenzaVacanzeClass">{{ contenuto.type }}</div>
                <div class="nomSos"><a :href="contenuto.lien" target="new"> {{ contenuto.penitenziere }}</a></div>
              </div>
            </div>

            <div class="arr-par-volo">
              <div>Partenza: <strong>{{ contenuto.partenza }}</strong> </div>
              <div>Arrivo: <strong>{{ contenuto.arrivo }}</strong> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from 'primevue/calendar'
import axios from 'axios'
import $ from 'jquery';

export default {
  name: "accoglienza-vacanze",
  components: { Calendar },
  data() {
    return {
      laDate: "",
      dateOn: false,
      calenders: [],
      calendersJSON: "",
      evenements: {},
      displayList: false,
      loading: true,
      content: [],
      contentDef: [],
      vars: {},
      descriptionLines: [],
      sostitutoDi: "",
      laDateDebutListing: null,
    };
  },
  computed: {
  },
  mounted() {
    // Date of today
    const today = new Date();
    this.searchForEvents( today );
  },
  methods: {

    printContent() {
      $('.navigation-bar').css('display', 'none')
      $('.p-tabmenu').css('display', 'none')
      $('#calendarForm').css('display', 'none')
      window.print();
      $('.navigation-bar').css('display', 'flex')
      $('.p-tabmenu').css('display', 'block')
      $('#calendarForm').css('display', 'block')
    },

    searchForEvents( dateParam ) {

      this.loading = true;

      const yyyy = dateParam.getFullYear();
      let mm = dateParam.getMonth() + 1; // Months start at 0!
      let dd = dateParam.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      this.laDate = dd + '/' + mm + '/' + yyyy;
      this.laDateDebutListing = dateParam

      this.fetchCalendar(6);
    },

      dateOnOff() {
        this.dateOn = !this.dateOn;
      },

    async fetchCalendar(months) {

      console.log('ladate =', "---" + this.laDate)

      this.evenements = {};

      //const queryString = window.location.search;
      //const urlParams = new URLSearchParams(queryString);
      //if (urlParams.has('debut')) { 
        //var dateDebutVariable = urlParams.get('debut') }
      //else { 
      var ladate = this.laDate.split("/");
      var newLaDate = ladate[2] + '-' + ladate[1] + '-' + ladate[0];

      var laDate_moins1_obj = new Date( newLaDate );
      laDate_moins1_obj.setDate(laDate_moins1_obj.getDate() - 1);
      var dateDebutVariable = laDate_moins1_obj.toISOString().substring(0, 10);
      //}
      console.log('datedebutvariable = ', dateDebutVariable)

      this.calenders[0] = "penitenzieri.vaticano@gmail.com"; // Penitenzieri's Calendar
      this.calendersJSON = JSON.stringify(this.calenders);

      axios.get(this.$store.state.host + "php/getCalendarOspitiFrati.php", { params: { periode: months, cal: this.calendersJSON, debut: dateDebutVariable } })
        .then(response => {

          console.log('response = ', response.data );
          
          this.evenements = response.data[1];
        console.log('evenement = ', this.evenements);

          this.content = [];
          this.contentDef = [];

          const regex = /assenz[ae]|vacanz[ae]/gi;
          for (var i = 0; i < this.evenements.length; i++) {

            const resSearch = this.evenements[i].type.search(regex);
            if ( resSearch != -1 ) { 

              this.vars = {};

              // parse start date
              var dateObj = new Date(this.evenements[i].startDate);
              this.vars.startDate = dateObj;
              this.vars.dayStart = dateObj.toLocaleString("it-IT", { day: "numeric" });
              this.vars.monthStartNum = dateObj.toLocaleString("it-IT", { month: "numeric" });

              // parse end date
              dateObj = new Date(this.evenements[i].endDate);
              this.vars.endDate = dateObj;
              if (this.evenements[i].startHeure == "") {
                dateObj.setDate(dateObj.getDate() - 1);
              }
              this.vars.dayEnd = dateObj.toLocaleString("it-IT", { day: "numeric" });
              this.vars.monthEndNum = dateObj.toLocaleString("it-IT", { month: "numeric" });

              // Add '0' before numbers < 10
              this.vars.dayStart = this.vars.dayStart < 10 ? "0" + this.vars.dayStart : this.vars.dayStart.toString();
              this.vars.dayEnd = this.vars.dayEnd < 10 ? "0" + this.vars.dayEnd : this.vars.dayEnd.toString();
              this.vars.monthStartNum = this.vars.monthStartNum < 10 ? "0" + this.vars.monthStartNum : this.vars.monthStartNum.toString();
              this.vars.monthEndNum = this.vars.monthEndNum < 10 ? "0" + this.vars.monthEndNum : this.vars.monthEndNum.toString();

              // traitement de la description
              this.vars.theDescription = this.evenements[i].description;
              this.vars.partenza = "";
              this.vars.arrivo = "";
              this.descriptionLines = [];

              if ( this.vars.theDescription != null ) {
                const descLines = this.vars.theDescription.split(String.fromCharCode(10)); // extract every lines the desc infos
                for (var x = 0; x < descLines.length; x++) { // parse every line of the desc
                  var lineElement = descLines[x].split('='); // split the line to retrieve the elements that are separated with =
                  this.descriptionLines[lineElement[0].toLowerCase()] = lineElement[1];
                }

                if ( this.descriptionLines.pen ) {
                  this.vars.sostitutoDi = this.descriptionLines.pen;
                }
            
                this.vars.partenza = this.descriptionLines.partenza;
                this.vars.arrivo = this.descriptionLines.arrivo;
              }
              this.vars.type = this.evenements[i].type ? this.evenements[i].type.trim() : "";
              this.vars.penitenziere = this.evenements[i].name
              this.vars.lien   = this.evenements[i].lien
              console.log('assenza = ', this.vars.penitenziere.toLowerCase())
              this.vars.assenzaVacanzeClass = this.vars.type.toLowerCase() == "assenza" ? "back-orange" : "back-blue"

              this.content.push( this.vars )
            }
          } 

          // *******************************
          // Sort the evenements by DATE ***
          // *******************************
          this.content.sort(function (a, b) {
            return new Date(a.dayData) - new Date(b.dayData);
          });

          console.log('content après sort', this.content)
          // supprime également les dates de début qui sont inférieures à this.laDate
          for (var key in this.content) {
              this.laDateDebutListing.setHours(0, 0, 0, 0);
              console.log('daydata = ', this.content[key].startDate)
              console.log('datedebutlisting = ', this.laDateDebutListing)
              if ( this.content[key].startDate >= this.laDateDebutListing ) {
                this.contentDef.push( this.content[key] )  // Inclus le record dans la liste définitive
              }
          }

          console.log(' contentdef vars = ', this.contentDef)

          this.loading = false;
          this.displayList = true;
      })
    },
  },
};
</script>
